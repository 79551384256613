<template>
  <section class="kona-tree">
    <!-- search bar -->
    <div v-if="searchBar" :class="mainClass">
      <vs-input
        class="inputx search-input tree-search-input float-left"
        :placeholder="lang.treeView.tree.search.placeholder[languageSelected]"
        v-model="searchword"
        @keyup="search"
        icon-no-border
        icon="search"
      />
    </div>

    <kona-alert
      class="alert"
      v-show="selectedToMove !== ''"
      icon="MoveIcon"
      bg-color="rgba(var(--vs-primary), 0.15)"
      color="rgba(var(--vs-primary), 1)"
    >
      {{ lang.treeView.tree.alert[languageSelected] }}
    </kona-alert>

    <!-- tree -->
    <div class="vs-con-loading__container" ref="loadingContainer">
      <v-tree
        class="mt-base mb-base no-select"
        ref="tree"
        :canDeleteRoot="true"
        :data="treeData"
        :tpl="tpl === 'basic' ? tplBasic : tplFull"
        :halfcheck="halfcheck"
        :multiple="multiple"
        :radio="radio"
      />
      <span
        v-if="
          treeData && treeData[0] && !treeData[0].visible && searchword !== ''
        "
      >
        {{ lang.general.noDataText[languageSelected] }}
      </span>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import 'vue-tree-halower/dist/halower-tree.min.css'
import Vue from 'vue'
Vue.directive('observe-visibility', ObserveVisibility)
import { ObserveVisibility } from 'vue-observe-visibility'

export default {
  name: 'KonaTree',
  components: {
    VTree: () => import('vue-tree-halower'),
    KonaAlert: () => import('./KonaAlert.vue')
  },
  props: {
    tpl: {
      type: String,
      default: 'full'
    },
    treeData: {
      type: Array,
      required: true
    },
    searchBar: {
      type: Boolean,
      default: true
    },
    showJump: {
      type: Boolean,
      default: true
    },
    halfcheck: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    radio: {
      type: Boolean,
      default: false
    },

    canMove: {
      type: Boolean,
      default: true
    },
    move: {
      type: Function
    },

    serachWidht: {
      type: String
    },
    marginTop: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      key: new Date().getTime(),

      searchword: '',

      selectedToMove: '',
      selectedToMove_id: '',
      selectedToMovePrevious: '',
      selectedToMoveNext: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'bot',
      'dialogs',
      'getMessageById',
      'activeVersion'
    ]),
    mainClass() {
      if (!this.marginTop) {
        return {
          'mt-1': true
        }
      }
      const ret = {}
      ret[this.marginTop] = true
      return ret
    },
    searchClass() {
      let ret = {}
      if (this.serachWidht) {
        ret[this.serachWidht] = true
      } else {
        ret = {
          'sm:w-1/2': true,
          'md:w-1/3': true,
          'lg:w-1/4': true,
          'xl:w-1/4': true
        }
      }
      return ret
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'SET_BOT_ID',
      'SET_DIALOG_MSG_ID',
      'SET_CONDITIONS_IN_EDIT'
    ]),
    ...mapMutations('bots', ['SET_BOT']),
    tplBasic(...args) {
      let { 0: node } = args
      let titleClass = node.selected
        ? ' node-title node-selected'
        : ' node-title'

      titleClass += node.current ? ' current-node' : ''

      return (
        <span>
          <span key={this.key}>
            <span
              class={titleClass}
              domPropsInnerHTML={this.getTitle(node.title)}
              onClick={() => this.selectNode(node)}
            ></span>
          </span>
          <span
            class={node.responses ? 'node-response' : 'node-response hidden'}
          >
            <i class="material-icons"> chat_bubble_outline </i>
            {node.responses}
          </span>
          <span
            class={node.validation ? 'node-response' : 'node-response hidden'}
          >
            <i class="material-icons"> check_circle_outline </i>
            {node.validation}
          </span>
        </span>
      )
    },
    tplFull(...args) {
      let { 0: node } = args
      let titleClass = node.selected
        ? ' node-title node-selected'
        : ' node-title'

      titleClass += node.current ? ' current-node' : ''
      const jumpClass =
        node.parent !== '0' || !this.showJump ? ' hide-option' : ''

      let regex
      // if (this.isRegex(node.title)) {
      //   regex = (
      //     <span class="regex" title="regex">
      //       regex
      //     </span>
      //   )
      // }

      let rowClass = ''
      if (node.id === this.selectedToMove) {
        rowClass = 'selected-to-move p-2 ml-2'
      }

      return (
        <span>
          <span class={rowClass} key={this.key}>
            <span
              class={titleClass}
              domPropsInnerHTML={this.getTitle(node.title)}
              onClick={() => this.selectNode(node)}
              v-observe-visibility={() => this.stopTreeLoading()}
            ></span>
            {regex}
            <vs-button
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-move"
              disabled={this.readOnly || this.selectedToMove !== ''}
              class={
                this.selectedToMove !== '' || !node.id || !this.canMove
                  ? 'hide-option'
                  : ''
              }
              onClick={() =>
                this.selectToMove(node.id, node._id, node.previous, node.next)
              }
            ></vs-button>
            <vs-button
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-corner-up-right"
              class={
                this.selectedToMove !== '' || !node.id
                  ? 'hide-option'
                  : jumpClass
              }
              disabled={this.selectedToMove !== ''}
              onClick={() => this.navigateToDialog(node)}
            ></vs-button>
            <vs-button
              v-tooltip={
                this.lang.treeView.tree.moveBelow[this.languageSelected]
              }
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-arrow-down-left"
              class={`${
                this.selectedToMove === '' ||
                !node.id ||
                this.selectedToMove === node.id ||
                this.selectedToMovePrevious === node.id
                  ? 'hide-option'
                  : 'text-success'
              }`}
              disabled={
                this.selectedToMovePrevious === node.id ||
                (this.selectedToMove &&
                  node.path &&
                  node.path.includes(this.selectedToMove))
              }
              onClick={() => this.moveSelectedTo(node.next, node.parent)} // EL NEXT ES EL NEXT DE ESTE, PORQUE HAY QUE PONERLO ABAJO
            ></vs-button>
            <vs-button
              v-tooltip={
                this.lang.treeView.tree.moveAbove[this.languageSelected]
              }
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-arrow-up-left"
              class={`${
                this.selectedToMove === '' ||
                !node.id ||
                this.selectedToMove === node.id ||
                this.selectedToMoveNext === node.id
                  ? 'hide-option'
                  : 'text-success'
              }`}
              disabled={
                this.selectedToMoveNext === node.id ||
                (this.selectedToMove &&
                  node.path &&
                  node.path.includes(this.selectedToMove))
              }
              onClick={() => this.moveSelectedTo(node.id, node.parent)} // EL NEXT ES ESTE, PORQUE HAY QUE PONERLO ARRIBA
            ></vs-button>
            <vs-button
              v-tooltip={
                this.lang.treeView.tree.moveAsASon[this.languageSelected]
              }
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-corner-right-down"
              class={`${
                this.selectedToMove === '' ||
                !node.id ||
                this.selectedToMove === node.id ||
                node.type === 'jump'
                  ? 'hide-option'
                  : 'text-success'
              }`}
              disabled={
                this.selectedToMove &&
                node.path &&
                node.path.includes(this.selectedToMove)
              }
              onClick={() => this.moveSelectedTo(undefined, node.id)} // EL NEXT ES ESTE, PORQUE HAY QUE PONERLO ARRIBA
            ></vs-button>
            <vs-button
              v-tooltip="Cancelar"
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-x"
              class={`${
                this.selectedToMove === node.id ? 'text-danger' : 'hide-option'
              }`}
              onClick={() => (this.selectedToMove = '')}
            ></vs-button>
          </span>
          <div
            class={node.responses ? 'node-response' : 'node-response hidden'}
          >
            <i class="material-icons"> chat_bubble_outline </i>
            {node.responses}
          </div>
          <span
            class={node.validation ? 'node-response' : 'node-response hidden'}
          >
            <i class="material-icons"> check_circle_outline </i>
            {node.validation}
          </span>
        </span>
      )
    },
    moveSelectedTo(next, parent) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.treeView.tree.confirmation.title[
          this.languageSelected
        ],
        text: this.lang.treeView.tree.confirmation.text[this.languageSelected],
        acceptText: this.lang.treeView.tree.confirmation.acceptText[
          this.languageSelected
        ],
        cancelText: this.lang.treeView.tree.confirmation.cancelText[
          this.languageSelected
        ],
        accept: () => {
          this.move(
            this.selectedToMove_id,
            next,
            `${parent}` !== '0' ? parent : undefined
          )
        }
      })
    },
    selectToMove(
      selectedToMove,
      selectedToMove_id,
      selectedToMovePrevious,
      selectedToMoveNext
    ) {
      this.selectedToMove = selectedToMove
      this.selectedToMove_id = selectedToMove_id
      this.selectedToMovePrevious = selectedToMovePrevious
      this.selectedToMoveNext = selectedToMoveNext
      this.key = new Date().getTime()
    },
    isRegex(text) {
      return text && text.startsWith('^(') && text.endsWith(')$')
    },
    // isRegex(text) {
    //   return text && text.startsWith('^(') && text.endsWith(')$')
    // },
    getTitle(title) {
      // return this.$options.filters.regex(title)
      return title
    },
    // getResponses(responses) {
    //   if (!responses) return

    //   let responsesTexts = []
    //   responses.forEach(r => responsesTexts.push(r.responseText))
    //   return responsesTexts.join(' ,')
    // },
    selectNode(node) {
      this.$refs.tree.nodeSelected(node)
      const selectedNodes = this.$refs.tree.getSelectedNodes()
      this.$emit('clickNode', selectedNodes)
    },
    navigateToDialog(node) {
      if (!this.bot.legacy) {
        let botName = this.bot.service.name
        let botId = this.bot.id
        let serviceId = this.bot.service.id
        this.SET_BOT(this.bot)

        const dialog = this.dialogs.find(d => d.dialogId === node.id)
        const msgId = dialog.idUserMessage
        this.SET_DIALOG_MSG_ID(msgId)
        this.SET_CONDITIONS_IN_EDIT(this.getMessageById(msgId))

        this.$router.push({
          path: '/bot-maker/bot/' + botName,
          query: {
            botId: botId,
            serviceId: serviceId,
            version: this.activeVersion
          }
        })
      }
    },
    normalizeString(string) {
      return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    search() {
      const searchWord = this.normalizeString(this.searchword)

      this.$refs.tree.searchNodes(node => {
        const title = node.title && this.normalizeString(node.title)
        const responses = node.responses && this.normalizeString(node.responses)
        const validation =
          node.validation && this.normalizeString(node.validation)

        return (
          new RegExp(searchWord, 'i').test(title) ||
          new RegExp(searchWord, 'i').test(responses) ||
          new RegExp(searchWord, 'i').test(validation)
        )
      })
    },
    enterSearch(event) {
      // "Enter" key on the keyboard
      if (event.keyCode === 13) {
        event.preventDefault()
        document.getElementById('search-button').click()
      }
    },
    resetSearch() {
      this.searchword = ''
      this.search()
    },
    reset() {
      this.searchword = ''
    },
    stopTreeLoading() {
      this.$vs.loading.close(this.$refs.loadingContainer)
    }
  },
  mounted() {
    // click on reset search icon
    // const resetSearch = document.getElementsByClassName('icon-after')[0]
    // resetSearch.addEventListener('click', this.resetSearch)
    this.$vs.loading.close()
    // TODO: working on tree view but not in jumps
    // this.$vs.loading({
    //   container: this.$refs.loadingContainer,
    //   background: 'white',
    //   text: this.lang.treeView.tree.loading[this.languageSelected]
    // })
    // enter on search input
    const searchInput = document.getElementsByClassName('search-input')[0]
    searchInput.addEventListener('keyup', this.enterSearch)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.resetSearch)
    document.removeEventListener('keyup', this.enterSearch)
  }
}
</script>

<style lang="scss">
.kona-tree {
  display: flex;
  flex-direction: column;
  .tree-search-input {
    max-width: 500px;
  }
  .alert {
    width: fit-content;
  }
  .halo-tree.scroll {
    // max-height: calc(100vh - 400px);
    overflow-y: auto;
  }

  .halo-tree .inputCheck {
    top: 2px;
  }
  .vs-input--icon.icon-after {
    cursor: pointer;
  }
  .vs-button.includeIcon {
    display: inline;
    i.feather {
      display: inline;
    }
    &.hide-option {
      display: none;
    }
  }
  .halo-tree .node-title.node-searched,
  .halo-tree .node-title.current-node {
    background-color: rgba(var(--vs-primary), 1);
    color: white;
    font-weight: 600;
  }
  .halo-tree .regex {
    font-size: 0.6rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    padding: 0 2px;
    border: solid 1px rgba(var(--vs-primary), 1);
    border-radius: 3px;
    margin: 0 4px;
    white-space: nowrap;
  }
  .halo-tree .node-response {
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.9rem;
    padding-left: 24px;

    &.hidden {
      display: none;
    }

    .material-icons {
      font-size: 0.8rem;
      line-height: 0.8rem;
      margin-right: 5px;
    }
  }
  .halo-tree li:before {
    border-left: 1px solid #888;
  }
  .halo-tree li.leaf:after,
  .halo-tree li:after {
    border-top: 1px solid #888;
  }
  .halo-tree > li.only-node:after {
    border-top: none;
  }
  .vs-con-input-label .vs-con-input {
    overflow-x: hidden;
  }
}
</style>

<style scoped>
.selected-to-move {
  border-style: outset;
}
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.search-input {
  border: none;
}
.kona-tree .vs-con-loading__container {
  min-height: 500px;
  margin-top: 10px;
}
</style>
